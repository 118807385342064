
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
import { defineComponent } from "vue";
// import { findSubId, destory, search } from "../../api/category.js";
// import { tree, addLabelValue } from "../../utils/tools";

import { client } from '@/client';
import { ElMessage } from "element-plus";
import { addLabelValue, tree } from "@/utils/tools";
export default defineComponent({
  name: "category-index",
  setup() {
    return {
      Edit,
      Delete,
      View,
      Search,
    };
  },
  data: () => {
    return {
      keywords: "", //关键词
      loading: true,
      tableData: [] as any[],
      multipleSelection: [],
    };
  },
  computed: {},
  created() {
    this.search();
  },
  methods: {
    handleClick(tab: any) {
      console.error(tab);
      // this.activeIndex = tab.index;
    },
    //清空
    clearSearch() {
      this.keywords = "";
      this.tableData = [];
      this.multipleSelection = [];
      this.search();
    },

    //查询
    async search() {
      try {
        let res = await client.callApi("category/Search",{key:this.keywords});
        if (res.isSucc) {
          let data = tree(res.res.list);
          console.log("data->", data);
          this.tableData = addLabelValue(data);

          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //全选和反选
    toggleSelection(rows: any[]) {
      let refs:any = this.$refs.multipleTable;
      if (rows) {
        rows.forEach((row: any) => {
          refs.toggleRowSelection(row);
        });
      } else {
        refs.clearSelection();
      }
    },

    //选择
    handleSelectionChange(val: never[]) {
      this.multipleSelection = val;
    },

    //编辑
    toEdit(e: { id: any; }) {
      let id = e.id;
      this.$router.push({ name: "category-edit", params: { id: id } });
    },

    //查询是否有子栏目
    async hasChild(id: any) {
        let res = await client.callApi("category/FindSubId",{id:id});
        if (res.isSucc) {
          if (res.res.list.length > 0) {
            return true;
          } else {
            return false;
          }
        }else{
          return false;
        }
    },

    //删除
    async del(id: any) {
      try {
        let res = await client.callApi("category/Del",{id:id});
        if (res.isSucc) {
          ElMessage({
            message: "删除成功 ^_^",
            type: "success",
          });
          this.clearSearch();
        }
      } catch (error) {
        console.log(error);
      }
    },
    //删除栏目  还需要判断是否当前栏目下面有文章？
    async handleDel(e: { id: any; }) {
      let id = e.id;
      let has = await this.hasChild(id);
      //判断是否有子栏目
      if (has) {
        ElMessage({
          message: "我下面还有栏目啊 ^_^",
          type: "success",
        });
        return false;
      } else {
        this.del(id);
      }
    },
  },
});
